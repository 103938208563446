<template>
  <div id="app">
    <!--    <div id="nav">-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--      <router-link to="/about">About</router-link> |-->
    <!--      <router-link to="/login">Login</router-link>-->
    <!--    </div>-->
    <router-view />
  </div>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { useSound } from "@vueuse/sound";
import notiSound from "@/assets/notification.wav";
import { API_URL } from "./_plugins/axios";

export default {
  methods: {
    ...mapActions(["addNotification"]),
    getNotis(user_id = null) {
      if (user_id === null) {
        API_URL.get("notifications").then((res) => {
          this.addNotification(res.data.data);
        });
      } else {
        API_URL.get(`notifications?user_id=${user_id}`).then((res) => {
          this.addNotification(res.data.data);
        });
      }
    },
  },
  setup() {
    const { play } = useSound(notiSound);

    return {
      play,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    let prefix = "App\\Events\\";
    // console.log(this.user);
    if (this.user !== null) {
      if (
        JSON.parse(localStorage.getItem("permissions")).roles.includes(
          "support"
        ) ||
        JSON.parse(localStorage.getItem("permissions")).roles.includes(
          "super-admin"
        )
      ) {
        this.getNotis();
        window.pusher
          .subscribe("new-request")
          .bind(`${prefix}NewRequestEvent`, () => {
            this.play();
            this.$toast.info("Novo obavještenje");
            this.getNotis();
          });
      } else if (
        JSON.parse(localStorage.getItem("permissions")).roles.includes("user")
      ) {
        this.getNotis(this.user.id);
        window.pusher
          .subscribe(`new-request-${this.user.id}`)
          .bind(`${prefix}RequestActionEvent`, () => {
            this.play();
            this.$toast.info("Novo obavještenje");
            this.getNotis(this.user.id);
          });

        window.pusher
          .subscribe(`new-document-${this.user.id}`)
          .bind(`${prefix}NewDocumentEvent`, () => {
            this.play();
            this.$toast.info("Novo obavještenje");
            this.getNotis(this.user.id);
          });
      }
    }
  },
  created() {
    Vue.prototype.$toast = this.$toast;
    Vue.prototype.$t = this.$t;
  },
};
</script>
