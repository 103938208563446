// import JQuery from 'jquery'
export default {
  name: "app-header",
  methods: {
    collapedSidebar: function () {
      let element = document.querySelector("body");
      element.classList.toggle("sidebar-icon-only");
    },
    collapedMobileSidebar: function () {
      let element = document.querySelector("#sidebar");
      element.classList.toggle("active");
    },
  },
};
