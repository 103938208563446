<template>
  <div v-if="form.errors.has(field)">
    <span class="text-danger">
      {{ form.errors.get(field) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "HasError",
  props: ["form", "field"],
  mounted() {},
};
</script>

<style scoped></style>
