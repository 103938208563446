import { Form } from "vform";
import { API_URL } from "./axios";
import { serializeForm } from "./utilities";

Form.prototype.submit = function (requestMethod, url, config = {}) {
  this.startProcessing();

  let method = requestMethod;
  // console.log(this.data());
  let data = method === "get" ? { params: this.data() } : this.data();
  // console.log(data);

  if (Object.values(data).some((item) => item instanceof File)) {
    if (method === "put") {
      data["_method"] = "PUT";
      method = "post";
    }

    data = serializeForm(data);
  }

  Object.values(data).some((item) => {
    if (item instanceof Array) {
      if (item[0] instanceof File) {
        data = serializeForm(data);
      }
    }
    // console.log(item);
  });

  return new Promise((resolve, reject) => {
    API_URL.request({ url: this.route(url), method, data, ...config })
      .then((response) => {
        this.finishProcessing();

        resolve(response);
      })
      .catch((error) => {
        this.busy = false;

        if (error.response) {
          this.errors.set(this.extractErrors(error.response));
        }

        reject(error);
      });
  });
};

export default Form;
