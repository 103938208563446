import { API_URL } from "@/_plugins/axios";

const state = {
  token: localStorage.getItem("access_token") || null,
  user: JSON.parse(localStorage.getItem("user")),
};

const getters = {
  loggedIn(state) {
    return state.token !== null;
  },
  user(state) {
    return state.user;
  },
  role(state) {
    return state.role;
  },
};

const actions = {
  retrieveToken({ commit }, user) {
    return new Promise((resolve, reject) => {
      API_URL.post("auth/login", user)
        .then((response) => {
          // console.log(response);
          const token = "Bearer " + response.data.access_token;
          localStorage.setItem("access_token", token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem(
            "permissions",
            JSON.stringify({
              permissions: response.data.user.permissions,
              roles: response.data.user.roles,
            })
          );
          API_URL.defaults.headers.common["Authorization"] = token;
          // commit("")
          // API_URL.get("notifications").then((res) => {
          //
          //   commit("./incrementNotification", res.data.data);
          // });
          commit("retrieveToken", token);
          commit("retrieveUser", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem("access_token");
          reject(error);
        });
    });
  },
  destroyToken({ commit }) {
    return new Promise((resolve) => {
      if (this.getters["auth/loggedIn"]) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        delete API_URL.defaults.headers.common["Authorization"];
        commit("destroyToken");
        resolve();
      }
    });
  },
  updateUser({ commit }, payload) {
    localStorage.setItem("user", JSON.stringify(payload));
    commit("updateUser", payload);
  },
};

const mutations = {
  retrieveToken(state, token) {
    state.token = token;
  },
  retrieveUser(state, user) {
    state.user = user;
  },
  destroyToken(state) {
    state.token = null;
    state.user = null;
  },
  updateUser(state, payload) {
    state.user = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
