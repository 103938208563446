import { serialize } from "object-to-formdata";

export const truncateText = (text, limit) => {
  return text && text.length > limit ? text.substring(0, limit) + "..." : text;
};

export const serializeForm = (data) => {
  return serialize(data, { indices: true });
  // return JSON.stringify(data);
};
