export default {
  install(Vue) {
    Vue.prototype.can = function (value) {
      let permissions = JSON.parse(
          localStorage.getItem("permissions")
        ).permissions,
        _return = false;
      if (!Array.isArray(permissions)) {
        return false;
      }
      if (value.includes("|")) {
        value.split("|").forEach(function (item) {
          if (permissions.includes(item.trim())) {
            _return = true;
          }
        });
      } else if (value.includes("&")) {
        _return = true;
        value.split("&").forEach(function (item) {
          if (!permissions.includes(item.trim())) {
            _return = false;
          }
        });
      } else {
        _return = permissions.includes(value.trim());
      }
      return _return;
    };

    Vue.prototype.is = function (value) {
      let roles = JSON.parse(localStorage.getItem("permissions")).roles,
        _return = false;
      if (!Array.isArray(roles)) {
        return false;
      }
      if (value.includes("|")) {
        value.split("|").forEach(function (item) {
          if (roles.includes(item.trim())) {
            _return = true;
          }
        });
      } else if (value.includes("&")) {
        _return = true;
        value.split("&").forEach(function (item) {
          if (!roles.includes(item.trim())) {
            _return = false;
          }
        });
      } else {
        _return = roles.includes(value.trim());
      }
      return _return;
    };
  },
};
