import axios from "axios";
import store from "@/store";
import router from "@/router";
import Vue from "vue";
import i18n from "@/_plugins/i18n";

// Adds authorization header when page is refreshed
const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

export const API_URL = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  //   headers: {
  //     Authorization: 'Bearer 123456',
  //     'Content-Type': 'application/json'
  //   }
});

// Add a request interceptor
API_URL.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
API_URL.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      // Vue.prototype.$toast.warning("Session expired");
      store.dispatch("auth/destroyToken").then(() => {
        router.push("/login");
      });
    } else if (error.response.status === 500) {
      Vue.prototype.$toast.error("Došlo je do greške");
    } else if (error.response.status === 422) {
      Vue.prototype.$toast.warning(i18n.tc("notifications.something_is_wrong"));
    } else if (error.response.status === 403) {
      router.push("/");
      Vue.prototype.$toast.error(i18n.tc("notifications.no_right"));
    }
    return Promise.reject(error);
  }
);
